body .beamer_icon.active,
#beamerIcon.active {
    width: 16px;
    height: 16px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ff3e43 !important
}

.beamerAnnouncementBarTag {
    background: var(--bg-categories) !important;
    color: var(--text-primary);
}

.beamerAnnouncementSnippetContent {
    border-radius: 4px;
}

.active,
.beamerAnnouncementSnippetContent,
.beamerAnnouncementBar {
    background: var(--bg-primary) !important;
    color: var(--text-primary);
}

#beamerLastPostTitle[x-placement^="left"] .popper__arrow:before,
.beamerAnnouncementBarTag:after {
    border-color: transparent transparent transparent var(--bg-categories) !important;
}

#beamerLastPostTitle[x-placement^="left"] .popper__arrow:after {
    border-color: transparent transparent transparent var(--bg-primary) !important;
}

#beamerLastPostTitle[x-placement^="bottom"] .popper__arrow:after {
    border-color: transparent transparent var(--bg-primary) transparent !important;
}

.beamerClose,
.beamerAnnouncementSnippetClose,
.beamerAnnouncementBar .beamerAnnouncementBarContent .beamerAnnouncementBarClose svg {
    fill: var(--text-primary) !important;
    color: var(--text-primary) !important;
}

.pushContentText,
.beamerTitle,
.beamerAnnouncementSnippetTitle,
.beamerAnnouncementBarContent {
    color: var(--text-primary) !important;
}

.beamerAnnouncementSnippetText {
    color: var(--text-secondary) !important;
}

.pushContentImage {
    background-image: url("https://companieslogo.com/img/orig/TENB-ccc3ae65.png?t=1720244494") !important;
}

body.dark .pushContentImage {
    filter: invert(1);
}

.beamer_beamer.right {
    width: 500px !important;
    box-shadow: var(--box-shadow) !important;
}