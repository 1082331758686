@media (prefers-color-scheme: dark) {
  body:not(.graphiql-light) .graphiql-container, body:not(.graphiql-light) .CodeMirror-info, body:not(.graphiql-light) .CodeMirror-lint-tooltip, body:not(.graphiql-light) reach-portal {
    --color-base: 217.5, 8.5%, 18.4% !important;
  }
  body.graphql-light .graphiql-container, body.graphql-light .CodeMirror-info, body.graphql-light .CodeMirror-lint-tooltip, body.graphql-light reach-portal {
    --color-base: 0, 0%, 100% !important;
  }
}

@media (prefers-color-scheme: light) {
  body:not(.graphiql-dark) .graphiql-container, body:not(.graphiql-dark) .CodeMirror-info, body:not(.graphiql-dark) .CodeMirror-lint-tooltip, body:not(.graphiql-dark) reach-portal {
    --color-base: 0, 0%, 100% !important;
  }
  body.graphiql-dark .graphiql-container, body.graphiql-dark .CodeMirror-info, body.graphiql-dark .CodeMirror-lint-tooltip, body.graphiql-dark reach-portal {
    --color-base: 217.5, 8.5%, 18.4% !important;
  }
}